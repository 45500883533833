// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import type { FluidImage } from 'types/image';
import { Icon } from 'react-components/media';
import { Image } from 'components/core/image';
import { Type } from 'react-components/typography';
import { color, media } from 'react-components/styles';
import { isEmpty } from 'lodash';
import { theme } from 'styled-tools';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useEffect, useRef } from 'react';
import Separator from 'components/core/separator';
import Slider from 'components/core/slider';
import circleIcon from 'assets/svg/circle-large.svg';
import quoteIcon from 'assets/svg/quote.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

export type Props = {|
  splashImage: ?Object,
  testimonials: Array<{|
    image: FluidImage,
    jobTitle: string,
    message: string,
    name: string
  |}>,
  title: string
|};

/**
 * `Card` styled component.
 */

const Card = styled.div`
  background-color: ${color('white')};
  border: 1px solid ${color('blue200')};
  height: 100%;
  padding: 1rem;
  position: relative;
  text-align: center;

  ${media.min('lg')`
    padding: 2rem;
  `}
`;

/**
 * `StyledRawHtml` styled component.
 */

const StyledRawHtml = styled(RawHtml)`
  u {
    position: relative;
    text-decoration: none;

    &::before {
      background-color: ${color('green500')};
      bottom: -1px;
      content: '';
      height: 1.5rem;
      left: 0;
      position: absolute;
      right: 0;
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform ${theme('transitions.default')};
    }

    &::after {
      content: attr(aria-label);
      position: relative;
    }

    &[data-animate]::before {
      transform: scaleX(1);
    }
  }
`;

/**
 * `Section` styled component.
 */

const Section = styled.section`
  background-color: ${color('white')};
  color: ${color('blue800')};
  padding: 6rem 0 4.5rem;
  position: relative;
`;

/**
 * `StyledSlider` styled component.
 */

const StyledSlider = styled(Slider)`
  .slick-slide {
    & > div {
      padding: 0 1rem;

      ${media.min('lg')`
        padding: 0 2rem;
      `}
    }

    &:nth-child(2n - 1) > div > div {
      margin-top: 2.5rem;

      ${media.min('lg')`
        margin-top: 4.5rem;
      `}
    }

    &:nth-child(3n) > div > div {
      border-radius: 0.75rem 4rem 0.75rem 0.75rem;

      ${media.min('lg')`
        border-radius-top: 7.5rem;
      `}
    }

    &:nth-child(3n - 1) > div > div {
      border-radius: 0.75rem;
    }

    &:nth-child(3n - 2) > div > div {
      border-radius: 0.75rem 0.75rem 4rem 0.75rem;

      ${media.min('lg')`
        border-radius-bottom: 7.5rem;
      `}
    }
  }
`;

/**
 * `LeftCircleIcon` styled component.
 */

const LeftCircleIcon = styled(Icon).attrs({
  icon: circleIcon,
  size: '48vw'
})`
  color: ${color('blue200')};
  left: -5%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
`;

/**
 * `RightCircleIcon` styled component.
 */

const RightCircleIcon = styled(Icon).attrs({
  icon: circleIcon,
  size: '70vw'
})`
  color: ${color('blue200')};
  position: absolute;
  right: -55%;
  top: 0;
  transform: translateY(-25rem);
`;

/**
 * `RightSplash` styled component.
 */

const RightSplash = styled.div`
  display: none;

  ${media.min('md')`
    display: initial;
    position: absolute;
    right: 0;
    top: 0;
  `}
`;

/**
 * `QuoteIcon` styled component.
 */

const QuoteIcon = styled(Icon).attrs({
  icon: quoteIcon,
  size: '2.5rem'
})`
  color: ${color('blue200')};
  left: 1rem;
  position: absolute;
  top: 1rem;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H2)`
  color: ${color('blue800')};
  margin-bottom: 4rem;
  position: relative;
  text-align: center;

  ${media.min('md')`
    margin-bottom: 7rem;
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  background-color: ${color.transparentize('blue100', 0.25)};
  border: 2px solid ${color('white')};
  border-radius: 50%;
  box-shadow: ${theme('boxShadows.default')};
  height: 6rem;
  margin: 0 auto 1.5rem;
  overflow: hidden;
  width: 6rem;
`;

/**
 * `StyledSeparator` styled component.
 */

const StyledSeparator = styled(Separator)`
  background-color: ${color('blue200')};
  margin: 0 auto 0.5rem;
  max-width: 2.5rem;
`;

/**
 * `Testimonials` component.
 */

function Testimonials(props: Props): Node {
  const { splashImage, testimonials, title } = props;
  const titleRef = useRef();

  useEffect(() => {
    const titleElement = titleRef.current;

    if (!titleElement) {
      return;
    }

    const underline = titleElement.querySelector('u');

    if (underline) {
      underline.setAttribute('aria-label', underline.textContent);
      underline.textContent = '';
    }
  });

  useEffect(() => {
    const titleElement = titleRef.current;

    if (!titleElement) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const underline = entry.target.querySelector('u');

          if (underline) {
            underline.setAttribute('data-animate', 'true');
          }
        }
      },
      {
        rootMargin: '0px 0px -50% 0px'
      }
    );

    observer.observe(titleElement);

    return () => observer.disconnect();
  }, []);

  return !isEmpty(testimonials) && (
    <Section>
      <Container>
        <LeftCircleIcon />

        <RightCircleIcon />

        <RightSplash>
          <Image fixed={splashImage} />
        </RightSplash>

        <Title
          as={'h6'}
          ref={titleRef}
        >
          <StyledRawHtml>
            {title}
          </StyledRawHtml>
        </Title>

        <Separator />

        <StyledSlider slidesLength={testimonials.length}>
          {testimonials.map(({ image, jobTitle, message, name }, index) => (
            <Card
              index={index}
              key={index}
            >
              <QuoteIcon />

              <ImageWrapper>
                <Image fluid={image} />
              </ImageWrapper>

              <Type.Paragraph
                as={'div'}
                marginBottom={'2rem'}
                textAlign={'left'}
              >
                <RawHtml>
                  {message}
                </RawHtml>
              </Type.Paragraph>

              <StyledSeparator />

              <Type.H5
                as={'p'}
                color={color('blue600')}
                display={'block'}
                fontWeight={600}
              >
                {name}
              </Type.H5>

              <Type.Small color={color('blue500')}>
                {jobTitle}
              </Type.Small>
            </Card>
          ))}
        </StyledSlider>
      </Container>
    </Section>
  );
}

/**
 * Export `Testimonials` component.
 */

export default Testimonials;
