// @flow

/**
 * Module dependencies.
 */

import { BackgroundImage, Image } from 'components/core/image';
import { Button } from 'components/core/buttons';
import { Container } from 'components/core/layout';
import type { FluidImage } from 'types/image';
import { Type } from 'react-components/typography';
import { color, media } from 'react-components/styles';
import { useScrollPosition } from 'hooks/use-scroll-position';
import Badge from 'components/core/badge';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import SocialNetworks from 'components/social-networks';
import styled from 'styled-components';
import useBreakpoint from 'hooks/use-breakpoint';

/**
 * `Props` type.
 */

type Props = {|
  backgroundImage: ?FluidImage,
  buttons: Array<{|
    badge: string,
    buttonLabel: string,
    buttonUrl: string,
    disabled: boolean,
    id: string,
    theme: 'white' | 'primary'
  |}>,
  coinImage: ?FluidImage,
  description: string,
  footnote: string,
  image: ?FluidImage,
  title: string,
  wrapperRef: Object
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.header`
  align-items: center;
  background-color: ${color('blue800')};
  color: ${color('white')};
  display: flex;
  min-height: 730px;
  position: relative;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-rows: 5rem 1fr repeat(4, min-content) 5rem;

  ${media.min('ms')`
    grid-template-columns: min-content 1fr;
    grid-template-rows: 5rem repeat(4, min-content) 5rem;
  `}

  ${media.min('lg')`
    grid-template-columns: 5fr 1fr 4fr;
    grid-template-rows: 10rem repeat(4, min-content) 10rem;
  `}
`;

/**
 * `IllustrationCell` styled component.
 */

const IllustrationCell = styled.div`
  grid-column: 1;
  grid-row: 1 / span 2;
  pointer-events: none;

  ${media.min('ms')`
    align-self: end;
    grid-column: -2 / -1;
    grid-row: 2 / -1;
    transform: translateY(24%);
  `}

  ${media.min('lg')`
    align-self: center;
    grid-column: 2 / -1;
    grid-row: 1 / -1;
    transform: none;
  `}
`;

/**
 * `Illustration` styled component.
 */

const Illustration = styled.div`
  margin: 0 auto;
  max-width: 320px;

  ${media.min('ms')`
    margin: 0 0 0 auto;
    max-width: 400px;
    padding-left: 2.5rem;
  `}

  ${media.min('lg')`
    max-width: 555px;
  `}
`;

/**
 * `CoinWrapper` styled component.
 */

const CoinWrapper = styled.div`
  bottom: -238px;
  position: absolute;
  right: -37px;
  width: 390px;
  z-index: 1;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H1)`
  font-weight: 600;
  grid-column: 1;
  grid-row: 3;
  margin-bottom: 2.5rem;

  ${media.min('ms')`
    grid-column: 1 / 3;
    grid-row: 2;
  `}
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Type.H5).attrs({ as: 'div' })`
  grid-column: 1 / -1;
  grid-row: 4;
  margin-bottom: 3.5rem;

  ${media.min('ms')`
    grid-row: 3;
    padding-right: 16.67%;
  `}

  ${media.min('lg')`
    grid-column: 1;
    padding-right: 0;
  `}
`;

/**
 * `ButtonsWrapper` styled component.
 */

const ButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-column: 1;
  grid-gap: 2rem;
  grid-row: 5;
  width: 100%;

  ${media.min('sm')`
    grid-auto-flow: column;
    grid-auto-columns: 224px;
    width: min-content;
  `}

  ${media.min('ms')`
    grid-row: 4;
  `}
`;

/**
 * `StyledBadge` styled component.
 */

const StyledBadge = styled(Badge)`
  pointer-events: none;
  position: absolute;
  right: -0.5rem;
  top: 0;
  transform: translateY(-50%);
`;

/**
 * Get coin transform.
 */

function getCoinTransform(scrollPosition: number): string {
  const horizontal = Math.min(-80 + scrollPosition / 6, 0);
  const vertical = Math.min(-160 + scrollPosition / 3, 0);
  const scale = Math.min(0.8 + scrollPosition / 2400, 1);

  return `translate(${horizontal}px, ${vertical}px) scale(${scale})`;
}

/**
 * `Coin` component.
 *
 * Moved to a separate component so that the scroll event listener can only be
 * mounted on desktop.
 */

function Coin({ coinImage, wrapperRef }) {
  const scrollPosition = useScrollPosition(wrapperRef);

  return (
    <CoinWrapper style={{ transform: getCoinTransform(scrollPosition) }}>
      <Image fluid={coinImage} />
    </CoinWrapper>
  );
}

/**
 * `Hero` component.
 */

function Hero(props: Props): Node {
  const {
    backgroundImage,
    buttons,
    coinImage,
    description,
    footnote,
    image,
    title,
    wrapperRef
  } = props;

  const isDesktop = useBreakpoint('lg');

  return (
    <Wrapper>
      <BackgroundImage
        fluid={backgroundImage}
        objectFit={'cover'}
      />

      <Container>
        <Grid>
          <IllustrationCell>
            <Illustration>
              <Image fluid={image} />
            </Illustration>
          </IllustrationCell>

          {isDesktop && (
            <Coin
              coinImage={coinImage}
              wrapperRef={wrapperRef}
            />
          )}

          <Title>
            <RawHtml>
              {title}
            </RawHtml>
          </Title>

          <Lead>
            <RawHtml>
              {description}
            </RawHtml>
          </Lead>

          <ButtonsWrapper>
            {buttons.map(({
              badge,
              buttonLabel,
              buttonUrl,
              disabled,
              id,
              theme
            }) => (
              <Button
                colorTheme={theme}
                disabled={disabled}
                href={buttonUrl}
                key={id}
                variant={theme === 'white' ? 'outline' : 'fill'}
              >
                {buttonLabel}

                {badge && <StyledBadge>{badge}</StyledBadge>}
              </Button>
            ))}
          </ButtonsWrapper>

          {footnote && (
            <Type.Small
              display={'block'}
              paddingTop={'2rem'}
            >
              <RawHtml>
                {footnote}
              </RawHtml>
            </Type.Small>
          )}
        </Grid>
      </Container>

      <SocialNetworks />
    </Wrapper>
  );
}

/**
 * Export `Hero` component.
 */

export default Hero;
