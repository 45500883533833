// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import type { FixedImage, FluidImage } from 'types/image';
import { Icon } from 'react-components/media';
import { Image } from 'components/core/image';
import { Type } from 'react-components/typography';
import { color, media } from 'react-components/styles';
import { theme } from 'styled-tools';
import Badge from 'components/core/badge';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import arrowSvg from 'assets/svg/arrow.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  bubblesImage: ?FixedImage,
  features: Array<{|
    badge: string,
    description: string,
    id: string,
    title: string
  |}>,
  image: ?FluidImage
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.section`
  background-color: white;
  background-image: linear-gradient(-180deg, rgb(246, 248, 255) 0%, rgb(239, 243, 254) 75%, rgba(233, 238, 253, 0) 100%);
  color: ${color('blue800')};
  position: relative;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  padding: 3rem 0;

  ${media.min('md')`
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
  `}
`;

/**
 * `List` styled component.
 */

const List = styled.ul`
  padding-top: 2rem;

  ${media.min('sm')`
    padding-top: 3rem;
  `}

  ${media.min('lg')`
    padding-top: 8rem;
  `}
`;

/**
* `ListItem` styled component.
*/

const ListItem = styled.li`
  display: grid;
  grid-gap: 2rem;

  &:not(:last-child) {
    margin-bottom: 5rem;
  }

  ${media.min('md')`
    &:last-child {
      margin-bottom: 2rem;
    }
  `}
`;

/**
 * `FeatureGrid` styled component.
 */

const FeatureGrid = styled.div`
  display: grid;
  grid-gap: 1.5rem 2rem;
  grid-template-areas: 'title' 'content';

  ${media.min('sm')`
    grid-template-areas: 'title' 'content';
  `}

  ${media.min('lg')`
    grid-gap: 1.5rem 2rem;
    grid-template-areas: 'icon title' 'icon content';
    grid-template-columns: min-content 1fr;
  `}
`;

/**
 * `StyledRawHtml` styled component.
 */

const StyledRawHtml = styled(RawHtml)`
  a {
    color: ${color('blue500')};
    font-weight: 500;
    transition: color ${theme('transitions.default')};

    &:focus,
    &:hover {
      color: ${color('green500')};
    }
  }
`;

/**
* `StyledBadge` styled component.
*/

const StyledBadge = styled(Badge)`
  margin-left: 0.5rem;
  position: relative;
  top: -0.5em;
`;

/**
 * `IllustrationWrapper` styled component.
 */

const IllustrationWrapper = styled.div`
  margin-bottom: -20%;
  margin-left: auto;
  position: relative;
  width: 75%;

  ${media.min('md')`
    bottom: 0;
    display: grid;
    grid-template-rows: 5rem 1fr 5rem;
    margin: 0;
    max-height: 100%;
    max-width: 688px;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
  `}

  ${media.min('lg')`
    grid-template-rows: 7.5rem 1fr 5rem;
    padding-top: 0;
  `}
`;

/**
 * `Illustration` styled component.
 */

const Illustration = styled.div`
  ${media.min('md')`
    grid-row: 2;
    position: relative;
  `}
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  ${media.min('md')`
    left: 0;
    position: absolute !important;
    right: 0;
    top: 0;
  `}
`;

/**
 * `ArrowIcon` styled component.
 */

const ArrowIcon = styled(Icon).attrs({
  icon: arrowSvg,
  size: '2rem'
})`
  color: ${color('blue300')};
  grid-area: icon;

  ${media.max('lg')`
    display: none;
  `}
`;

/**
 * `BubblesWrapper` styled component.
 */

const BubblesWrapper = styled.div`
  bottom: -145px;
  left: -116px;
  position: absolute;
  transform: scaleX(-1);

  ${media.max('lg')`
    display: none;
  `}
`;

/**
 * `FeaturesList` component.
 */

function FeaturesList(props: Props): Node {
  const { bubblesImage, features, image } = props;

  return (
    <Wrapper>
      <Container>
        <Grid>
          <List>
            {features.map(({ badge, description, id, title }) => (
              <ListItem key={id}>
                <FeatureGrid>
                  <ArrowIcon />

                  <Type.H4
                    as={'h6'}
                    fontWeight={500}
                    gridArea={'title'}
                  >
                    {title}

                    {badge && <StyledBadge>{badge}</StyledBadge>}
                  </Type.H4>

                  <Type.Paragraph
                    as={'span'}
                    gridArea={'content'}
                  >
                    <StyledRawHtml>
                      {description}
                    </StyledRawHtml>
                  </Type.Paragraph>
                </FeatureGrid>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Container>

      <IllustrationWrapper>
        <Illustration>
          <StyledImage fluid={image} />
        </Illustration>
      </IllustrationWrapper>

      <BubblesWrapper>
        <Image fixed={bubblesImage} />
      </BubblesWrapper>
    </Wrapper>
  );
}

/**
 * Export `FeaturesList` component.
 */

export default FeaturesList;
