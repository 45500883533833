// @flow

/**
 * Module dependencies.
 */

import type { FluidImage } from 'types/image';
import { Image } from 'components/core/image';
import { Type } from 'react-components/typography';
import { color, media } from 'react-components/styles';
import Card from 'components/core/card';
import React, { type Node } from 'react';
import Separator from 'components/core/separator';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  items: Array<{|
    id: string,
    image: FluidImage,
    name: ?string
  |}>,
  title: string
|};

/**
 * `Content` styled component.
 */

const Content = styled.div`
  padding: 2.25rem 2rem 3rem;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;

  ${media.max('lg')`
    grid-auto-columns: calc(35% - 1rem * 2);
    grid-auto-flow: column;
    grid-gap: 1rem;
    margin: 0 -2rem;
    overflow-x: scroll;
    padding: 2rem 2rem 0 2rem;
    scroll-snap-type: x proximity;

    &::after {
      content: '';
      width: 1rem;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  `}

  ${media.max('md')`
    grid-auto-columns: calc(50% - 1rem * 2);
  `}

  ${media.max('sm')`
    grid-auto-columns: calc(95% - 1rem * 2);
  `}

  ${media.min('lg')`
    grid-gap: 1.5rem 2rem;
    grid-template-columns: repeat(10, 1fr);
    justify-content: center;
    margin-bottom: 1rem;
    padding-top: 3rem;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H3)`
  color: ${color('green500')};
  margin-bottom: 2.25rem;
  text-align: center;
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  position: relative;

  ${media.min('lg')`
    grid-column-end: span 2;

    &:nth-child(7n - 6) {
      grid-column-start: 3;
    }

    &:nth-child(7n - 3) {
      grid-column-start: 2;
    }
  `}
`;

/**
 * `ImagesGridCard` component.
 */

function ImagesGridCard({ items, title }: Props): Node {
  return (
    <Card>
      <Content>
        <Title as={'h6'}>
          {title}
        </Title>

        <Separator />

        <Grid>
          {items.map(({ id, image, name }) => (
            <ImageWrapper key={id}>
              <Image
                alt={name}
                fluid={image}
                height={'auto'}
                width={'100%'}
              />
            </ImageWrapper>
          ))}
        </Grid>
      </Content>
    </Card>
  );
}

/**
 * Export `ImagesGridCard` component.
 */

export default ImagesGridCard;
