// @flow

/**
 * Module dependencies.
 */

import { Box, Container } from 'components/core/layout';
import { Image } from 'components/core/image';
import type { TemplateProps } from 'types/template';
import { color, media } from 'react-components/styles';
import { getFluidImage } from 'utils/get-fluid-image';
import { graphql, useStaticQuery } from 'gatsby';
import { map } from 'lodash';
import FeaturesList from 'components/home/features-list';
import Footer from 'components/footer';
import HeroSection from 'components/home/hero';
import ImagesGridCard from 'components/home/images-grid-card';
import Metatags from 'components/metatags';
import Navbar from 'components/navbar';
import PageContainer from 'components/page-container';
import React, { type Node, useMemo, useRef } from 'react';
import Separator from 'components/core/separator';
import SubscriptionForm from 'components/core/subscription-form';
import TechnologiesList from 'components/home/technologies-list';
import Testimonials from 'components/home/testimonials';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: white;
  color: ${color('white')};
  height: 100vh;
  overflow: hidden auto;
`;

/**
 * `GradientBackground` styled component.
 */

const GradientBackground = styled.div`
  background: linear-gradient(180deg, ${color('blue700')} 0%, ${color('blue800')} 100%);
  padding-top: 9.5rem;

  ${media.min('lg')`
    bottom: 0;
    position: sticky;
  `}
`;

/**
 * `ImageGridSection` styled component.
 */

const ImageGridSection = styled.section`
  position: relative;
`;

/**
 * `LeftSplash` styled component.
 */

const LeftSplash = styled.div`
  display: none;

  ${media.min('md')`
    display: initial;
    left: 0;
    position: absolute;
    top: 24%;
  `}

  ${media.min('lg')`
    top: 42%;
  `}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  display: grid;
  grid-gap: 5rem;
  padding-bottom: 7.5rem;
  padding-top: 4.5rem;
`;

/**
 * Home page query.
 */

const homePageQuery = graphql`
  query {
    home: seegnoCmsHomePage {
      content {
        backers {
          title
          list {
            name
            image {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
            id
          }
        }

        technologies {
          title
          list {
            description
            id
            title
            image {
              file {
                url: publicURL
              }
            }
          }
        }

        partners {
          title
          list {
            name
            image {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
            id
          }
        }

        testimonials {
          title
          list {
            id
            image {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
            jobTitle
            message
            name
          }
        }
      }
    }

    images: seegnoCmsHomePage {
      content {
        header {
          backgroundImage {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }

          image {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }

          coinImage {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }
        }

        features {
          image {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }
        }
      }
    }

    bubblesImage: file(
      relativePath: { eq: "images/about-us-bubbles-right.png" }
    ) {
      childImageSharp {
        fixed(width: 360) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    splashLeftImage: file(
      relativePath: { eq: "images/splash-left.png" }
    ) {
      childImageSharp {
        fixed(width: 380) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    splashRightImage: file(
      relativePath: { eq: "images/splash-right.png" }
    ) {
      childImageSharp {
        fixed(width: 490) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

/**
 * `Home` page.
 */

function Home({ pageContext }: TemplateProps): Node {
  const {
    bubblesImage,
    home,
    images,
    splashLeftImage,
    splashRightImage
  } = useStaticQuery(homePageQuery);

  const wrapperRef = useRef();
  const content = pageContext?.content;
  const { backers, partners, testimonials } = useMemo(() => {
    const normalize = list => map(list, item => ({
      ...item,
      image: getFluidImage(item)
    }));

    return {
      backers: {
        list: normalize(home?.content?.backers?.list),
        title: home?.content?.backers?.title
      },
      partners: {
        list: normalize(home?.content?.partners?.list),
        title: home?.content?.partners?.title
      },
      testimonials: {
        list: normalize(home?.content?.testimonials?.list),
        title: home?.content?.testimonials?.title
      }
    };
  }, [home]);

  return (
    <PageContainer>
      <Wrapper ref={wrapperRef}>
        <Metatags {...pageContext?.metatags} />

        <Box
          backgroundColor={'white'}
          position={'relative'}
          zIndex={1}
        >
          <Navbar />

          <HeroSection
            backgroundImage={getFluidImage(images.content.header, 'backgroundImage')}
            buttons={content.header.buttons}
            coinImage={getFluidImage(images.content.header, 'coinImage')}
            description={content.header.description}
            footnote={content.header.infoLabel}
            image={getFluidImage(images.content.header)}
            title={content.header.title}
            wrapperRef={wrapperRef}
          />

          <FeaturesList
            bubblesImage={bubblesImage?.childImageSharp?.fixed}
            features={pageContext?.content?.features?.list}
            image={getFluidImage(images.content.features)}
          />

          <TechnologiesList
            items={home?.content?.technologies?.list}
            title={home?.content?.technologies?.title}
          />

          {testimonials?.list && (
            <Testimonials
              splashImage={splashRightImage?.childImageSharp?.fixed}
              testimonials={testimonials?.list}
              title={testimonials?.title}
            />
          )}

          <ImageGridSection>
            <StyledContainer>
              <ImagesGridCard
                items={backers?.list}
                title={backers?.title}
              />

              <ImagesGridCard
                items={partners?.list}
                title={partners?.title}
              />
            </StyledContainer>

            <LeftSplash>
              <Image fixed={splashLeftImage?.childImageSharp?.fixed} />
            </LeftSplash>
          </ImageGridSection>
        </Box>

        <GradientBackground>
          <Container>
            <SubscriptionForm />
          </Container>

          <Container>
            <Separator />
          </Container>

          <Footer />
        </GradientBackground>
      </Wrapper>
    </PageContainer>
  );
}

/**
 * Export `Home` page
 */

export default Home;
