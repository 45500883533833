// @flow

/**
 * Module dependencies.
 */

import { useEffect, useState } from 'react';

/**
 * Export `useScrollPosition`.
 */

export function useScrollPosition(elementRef: Object): number {
  const [scrollPosition, setScrollPosition] = useState(() => {
    return elementRef.current?.scrollTop ?? 0;
  });

  useEffect(() => {
    const element = elementRef.current;

    if (!element) {
      return;
    }

    setScrollPosition(element.scrollTop);

    function handleScroll(event) {
      requestAnimationFrame(() => {
        setScrollPosition(event.target.scrollTop);
      });
    }

    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [elementRef]);

  return scrollPosition;
}
