// @flow

/**
 * Module dependencies.
 */

import { Icon } from 'react-components/media';
import { Type } from 'react-components/typography';
import { breakpoints, color, media } from 'react-components/styles';
import { ifProp } from 'styled-tools';
import React, { type Node, useCallback, useRef, useState } from 'react';
import ReactSlickSlider from 'react-slick';
import chevronLeftIcon from 'assets/svg/chevron-left.svg';
import chevronRightIcon from 'assets/svg/chevron-right.svg';
import slickCarouselStyles from 'slick-carousel/slick/slick.css';
import styled, { createGlobalStyle, css } from 'styled-components';
import useBreakpoint from 'hooks/use-breakpoint';

/**
 * `Props` type.
 */

type Props = {|
  arrows: boolean,
  autoplay: boolean,
  centerPadding: string,
  children: Node,
  className?: string,
  dots: boolean,
  easing: string,
  infinite: boolean,
  responsive: Array<Object>,
  slidesLength: number
|};

/**
 * Settings.
 */

const settings = {
  arrows: false,
  autoplay: false,
  centerPadding: '40px',
  dots: false,
  easing: 'cubic-bezier(0.81, 0.26, 0.82, 0.44)',
  infinite: false,
  responsive: [{
    breakpoint: breakpoints.lg + 1,
    settings: {
      slidesToScroll: 1,
      slidesToShow: 2.1
    }
  },
  {
    breakpoint: breakpoints.sm + 1,
    centerPadding: '16px',
    settings: {
      slidesToScroll: 1,
      slidesToShow: 1.1
    }
  }],
  slidesToScroll: 1,
  slidesToShow: 3,
  speed: 500
};

/**
 * Export `useSlidesToShow` hook.
 */

export function useSlidesToShow(): number {
  const isTablet = useBreakpoint('sm', 'min');
  const isDesktop = useBreakpoint('lg', 'min');

  return isDesktop && 3 || isTablet && 2 || 1;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  position: relative;

  .slick-list {
    overflow: visible;
  }
`;

/**
 * `SlickCarouselGlobalStyles` component.
 */

const SlickCarouselGlobalStyles = createGlobalStyle`
  ${slickCarouselStyles}

  .slick-slide div[tabindex]:focus {
    opacity: 0.95;
    outline: none;
  }
`;

/**
 * Export `Pagination` styled component.
 */

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  min-width: 4rem;

  ${media.min('md')`
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 4.5rem;
    transform: translateX(100%);
  `}
`;

/**
 * Export `Label` styled component.
 */

export const Label = styled(Type.Label)`
  background-color: ${color.transparentize('blue200', 0.8)};
  border-radius: 12px;
  color: ${color('blue500')};
  display: inline-block;
  outline: none;
  padding: 0 12px;
  text-align: center;
`;

/**
 * Export `RoundButton` styled component.
 */

export const RoundButton = styled.button`
  align-items: center;
  background-color: ${color.transparentize('blue500', 0.8)};
  border: none;
  border-radius: 50%;
  color: ${color('white')};
  cursor: pointer;
  display: flex;
  height: 4rem;
  justify-content: center;
  margin-top: 3rem;
  outline: none;
  position: absolute;
  top: 3rem;
  width: 4rem;

  ${ifProp('disabled', css`
    opacity: 0.5;
    pointer-events: none;
  `)}

  ${ifProp('next', css`
    right: 0;
    transform: translateX(100%);
  `, css`
    left: 0;
    transform: translateX(-100%);
  `)}

  ${media.max('md')`
    bottom: -1.5rem;
    top: auto;
    transform: translateX(0%);
  `}
`;

/**
 * `Slider` component.
 */

function Slider({ className, slidesLength, ...rest }: Props): Node {
  const [slideIndex, setSlideIndex] = useState(0);
  const currentPage = Math.round(slideIndex + 1);
  const sliderRef = useRef();
  const slidesToShow = useSlidesToShow();
  const totalPages = Math.round(slidesLength - slidesToShow + 1);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) {
      return;
    }

    sliderRef.current.slickNext();
  }, []);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) {
      return;
    }

    sliderRef.current.slickPrev();
  }, []);

  return (
    <Wrapper className={className}>
      <SlickCarouselGlobalStyles />

      <ReactSlickSlider
        {...settings}
        {...rest}
        afterChange={setSlideIndex}
        ref={sliderRef}
      />

      <Pagination>
        <Label>
          {`${currentPage}/${totalPages}`}
        </Label>
      </Pagination>

      <RoundButton
        disabled={currentPage === 1}
        onClick={handlePrev}
      >
        <Icon
          icon={chevronLeftIcon}
          size={'2rem'}
        />
      </RoundButton>

      <RoundButton
        disabled={currentPage === totalPages}
        next
        onClick={handleNext}
      >
        <Icon
          icon={chevronRightIcon}
          size={'2rem'}
        />
      </RoundButton>
    </Wrapper>
  );
}

/**
 * Export `Slider` component.
 */

export default Slider;
