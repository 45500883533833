// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import { type ItemsList } from 'types/items-list';
import { Type } from 'react-components/typography';
import { color, media } from 'react-components/styles';
import React, { type Node } from 'react';
import SlidingListWithImage from 'components/core/sliding-list-with-image';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  items: Array<ItemsList>,
  title: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.section`
  background-color: ${color('white')};
  color: ${color('blue800')};
  overflow: hidden;
  padding-top: 3rem;

  ${media.min('sm')`
    padding-top: 4rem;
  `}

  ${media.min('lg')`
    padding-top: 7rem;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H2)`
  margin-bottom: 2rem;
  text-align: center;

  ${media.min('md')`
    margin-bottom: 3rem;
  `}

  ${media.min('lg')`
    margin-bottom: 4rem;
  `}
`;

/**
 * `TechnologiesList` component.
 */

function TechnologiesList(props: Props): Node {
  const { items, title } = props;

  return (
    <Wrapper>
      <Container>
        <Title>
          {title}
        </Title>

        <SlidingListWithImage items={items} />
      </Container>
    </Wrapper>
  );
}

/**
 * Export `TechnologiesList` component.
 */

export default TechnologiesList;
